.fulfillment-input .prefix {
  position: relative;
  margin-right: -1px;
  padding-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-width: 0;
  background-color: white;
  outline: 1px solid var(--text-color);
  box-shadow: var(--badge-shadow);
}

.fulfillment-input .prefix:after {
  content: '';
  position: absolute;
  width: 4px;
  top: 0;
  bottom: 0;
  right: -4px;
  background-color: white;
}

.fulfillment-input .prefix + input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fulfillment-input input {
  border-width: 0;
  outline: 1px solid var(--text-color);
  box-shadow: var(--badge-shadow);
}

.fulfillment-input input:focus {
  outline: 1px solid var(--text-color);
  outline-offset: 0;
  box-shadow: var(--badge-shadow);
}

.fulfillment-textarea textarea {
  outline: 1px solid var(--text-color);
  border-width: 0;
  border-style: solid;
  border-color: var(--text-color);
  box-shadow: var(--badge-shadow);
}

.fulfillment-textarea textarea:focus {
  outline: 1px solid var(--text-color);
  outline-offset: 0;
  box-shadow: var(--badge-shadow);
}
